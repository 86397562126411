import axios from "axios";

const {
  REACT_APP_MAIN_PROJECT_API,
  REACT_APP_VERTEX_API_URL,
  REACT_APP_VERTEX_API_KEY,
} = process.env;

export const api = axios.create({
  baseURL: REACT_APP_MAIN_PROJECT_API,
  withCredentials: true,
  transformResponse: axios.defaults.transformResponse.concat(
    (params) => params.data || params
  ),
});

export const operations_oracle_api = axios.create({
  baseURL: REACT_APP_VERTEX_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${REACT_APP_VERTEX_API_KEY}`,
  },
  transformResponse: axios.defaults.transformResponse.concat(
    (params) => params.data || params
  ),
});

export const createResponseInterceptor = (onFulfilled, onRejected) => {
  api.interceptors.response.use(onFulfilled, onRejected);
};
