import React, { useMemo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";

import { MessagesSelectors, UserSelectors, AppActions } from "../../store";
import { useChatActions } from "../hooks/useChatActions";
import { getFormattedMessages } from "../../pages/models/messages";
import PageContainer from "../../components/grid/PageContainer.jsx";
import Chat from "../../components/Chat/Chat";
import MessageForm from "../../components/Chat/MessageForm.jsx";
import { Footer } from "../../components/Footer/index.jsx";
import { NavigationButton } from "../../components/Button/NavigationButton.js";
import { trimString } from "../../utils/string.js";
import { PAGE_TITLE_ROUTE_MAP } from "../../constants/navigation.js";
import { ReactComponent as DPDLogo } from "../../assets/symbolDpdRed.svg";

// Container height needs to be set the same for all pages - Change to constant?
const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  height: "92%",
  overflow: "hidden",
}));

const ChatContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spaces.xLarge,
  overflowY: "auto",
  flexGrow: 1,
}));

const PictureMessage = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "4rem",
  fontFamily: "PlutoSansLight",
  color: theme.colors.brandBlack,
  gap: "0.6rem",
  marginTop: "12rem",
}));

const ButtonContainer = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, auto)",
  gap: "0.8rem",
  margin: "0 auto",
  marginBottom: "32px",
  justifyItems: "center",
}));

export const HomePage = () => {
  const dispatch = useDispatch();
  const chatContainerRef = useRef(null);

  const { setLoading } = AppActions;

  const { resetChat, onSendMessage, isTyping, activeTopicId } =
    useChatActions();
  const user = useSelector(UserSelectors.getUser);
  const messages = useSelector(MessagesSelectors.getMessages);

  const preparedMessages = useMemo(
    () => getFormattedMessages(messages),
    [messages]
  );

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessageToVertex = async (message) => {
    await onSendMessage(trimString(message));
    dispatch(setLoading(true));
    try {
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

  return (
    <PageContainer>
      <Container>
        <ChatContainer ref={chatContainerRef}>
          <PictureMessage>
            <DPDLogo />
            Hi, how can I help you?
          </PictureMessage>
          <ButtonContainer>
            {PAGE_TITLE_ROUTE_MAP.filter((product, i) => i !== 0).map(
              ({ productTitle, routeName }) => {
                return (
                  <NavigationButton
                    key={productTitle}
                    title={productTitle}
                    link={routeName}
                  />
                );
              }
            )}
          </ButtonContainer>
          <Chat messages={preparedMessages} user={user} isTyping={isTyping} />
        </ChatContainer>
      </Container>
      <Footer resetChat={resetChat}>
        <MessageForm
          sendMessageToVertex={sendMessageToVertex}
          isTyping={isTyping}
          activeTopicId={activeTopicId}
        />
      </Footer>
    </PageContainer>
  );
};
