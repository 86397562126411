import { v4 as uuidv4 } from "uuid";

import { messagesApi, topicsApi } from "../../../api";
import { createHandledAsyncThunk, createLoadingAsyncThunk } from "../utils";

export const fetchMessagesByTopicId = createHandledAsyncThunk(
  "messages/fetchMessagesByTopicId",
  async (topicId) => {
    const { data } = await topicsApi.getTopicMessages(topicId);

    return data;
  }
);

export const likeMessage = createLoadingAsyncThunk(
  "messages/likeMessage",
  async ({ messageId, userId }) => {
    const { data } = await messagesApi.likeMessage(messageId, userId);

    return data;
  }
);

export const dislikeMessage = createLoadingAsyncThunk(
  "messages/dislikeMessage",
  async ({ messageId, feedbackComment, userId }) => {
    const { data } = await messagesApi.dislikeMessage(messageId, {
      feedbackComment,
      userId,
    });

    return data;
  }
);

export const sendMessageDashboard = createHandledAsyncThunk(
  "messages/sendMessageDashboard",
  async ({ question, topicId, spannerUserId, userId }) => {
    const messageId = uuidv4();
    const res = await messagesApi.sendMessageToChatbot("", {
      message: question,
      spannerUserId,
      messageId,
      userId,
    });
    const { results, responseAI, naturalLanguageResponse, query } = res;
    const data = {
      attributes: {
        topicId,
        topicName: question,
      },
      cardData: null,
      messages: [
        {
          id: messageId,
          question: question,
          answer: naturalLanguageResponse.response,
          results,
          responseAI,
          query,
        },
      ],
    };
    return data;
  }
);

export const sendMessageVolumeSimulator = createHandledAsyncThunk(
  "messages/sendMessageVolumeSimulator",
  async ({ question, depots, topicId, spannerUserId, userId }) => {
    const messageId = uuidv4();
    const res = await messagesApi.sendMessageToChatbot("/volumeSimulator", {
      message: question,
      depots,
      spannerUserId,
      messageId,
      userId,
    });
    const {
      message,
      depotName,
      depotCode,
      depotCodeList,
      depotNameList,
      date,
      percentage,
      districtAndSectorList,
    } = res;

    const data = {
      attributes: {
        topicId,
        topicName: question,
      },
      cardData: null,
      messages: [
        {
          id: messageId,
          question: question,
          answer: message,
          depotName,
          depotCode,
          depotCodeList,
          depotNameList,
          date,
          percentage,
          districtAndSectorList,
        },
      ],
    };

    return data;
  }
);
