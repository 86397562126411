import { useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";

import { MessagesActions } from "../../store/index.js";
import MessageList from "./MessageList.jsx";
import FeedbackModal from "../modal/FeedbackModal.jsx";
import useModal from "../../pages/hooks/useModal.js";

const ChatPageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  gap: "1rem",
  flex: 1,
  flexDirection: "row",
  wordBreak: "break-word",
  alignItems: "center",
  paddingTop: "0.1rem",
  [theme.breakpoints.down("md")]: {
    width: "200vw",
    paddingBottom: `${theme.dimensions.chat.switchButtonsHeight}px`,
    transition: "0.5s ease",
    transform: "translateX(0)",
    margin: `0 ${theme.spaces.xxLarge}px`,
  },
}));

const Chat = ({ messages, user, isTyping }) => {
  const dispatch = useDispatch();

  const onThumbUpClick = useCallback(
    async (messageId) => {
      const { payload } = await dispatch(
        MessagesActions.likeMessage({ messageId, userId: user.id })
      );

      if (payload) {
        dispatch(MessagesActions.likeLocalMessage(messageId));
      }
    },
    [dispatch, user.id]
  );

  const onSubmitFeedback = useCallback(
    async (messageId, feedbackComment) => {
      const { payload } = await dispatch(
        MessagesActions.dislikeMessage({
          messageId,
          feedbackComment,
          userId: user.id,
        })
      );

      if (payload) {
        dispatch(MessagesActions.dislikeLocalMessage(messageId));
      }
    },
    [dispatch, user.id]
  );

  const { open, onModalOpen, onSubmitClick, onCancelClick } = useModal({
    onSubmit: onSubmitFeedback,
  });

  return (
    <ChatPageContainer>
      <MessageList
        user={user}
        messages={messages}
        onThumbDownClick={onModalOpen}
        onThumbUpClick={onThumbUpClick}
        isTyping={isTyping}
      />
      <FeedbackModal
        open={open}
        onCancel={onCancelClick}
        onSubmit={onSubmitClick}
      />
    </ChatPageContainer>
  );
};

export default Chat;
