import { api, operations_oracle_api } from "./api";

export const sendMessage = (body, queryParam, signal) => {
  const query = new URLSearchParams(queryParam);

  return api.post(`/messages/send?${query}`, body, { signal });
};

export const likeMessage = (messageId, userId) =>
  operations_oracle_api.post(`/feedback/${messageId}/like`, { userId });

export const dislikeMessage = (messageId, body) =>
  operations_oracle_api.post(`/feedback/${messageId}/dislike`, body);

export const sendMessageToChatbot = async (route = "", body) => {
  let response;
  try {
    response = await operations_oracle_api.post(route, JSON.stringify(body));

    if (!response?.data?.message) throw new Error("Failed to fetch answer");
  } catch (e) {
    console.log(e);
    if (e.response) response = e.response;
  } finally {
    return response?.data || {};
  }
};
