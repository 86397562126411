import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";

import Chat from "../../../components/Chat/Chat";
import { getFormattedMessages } from "../../models/messages";
import { MessagesSelectors, UserSelectors } from "../../../store";

const Segment = styled("div")(() => ({
  height: "100%",
  width: "100%",
  overflow: "hidden",
}));

export const VolumeSimulatorChat = ({ isTyping }) => {
  const messages = useSelector(MessagesSelectors.getMessages);
  const user = useSelector(UserSelectors.getUser);

  const preparedMessages = useMemo(
    () => getFormattedMessages(messages),
    [messages]
  );

  return (
    <Segment>
      <Chat messages={preparedMessages} user={user} isTyping={isTyping} />
    </Segment>
  );
};
