import React, { useMemo } from "react";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

const DialogContainer = styled(Dialog)(({ width }) => ({
  "& .MuiDialog-paper": {
    width: width || 600,
    maxWidth: "90vw",
    boxShadow: "0px 3px 5px 1px rgba(0, 0, 0, 0.2)",
  },
}));

const DialogHeader = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spaces.large,
  alignItems: "center",
  gap: theme.spaces.large,
}));

const HeaderTitles = styled(Box)({
  flex: 1,
});

const DialogTitleStyled = styled(DialogTitle, {
  shouldForwardProp: (prop) => prop !== "titleStyles",
})(({ theme, titleStyles }) => ({
  fontFamily: theme.fonts.plutoRegular,
  fontSize: 24,
  color: theme.colors.brandBlack,
  padding: 0,
  margin: 0,
  ...titleStyles,
}));

const DialogSubtitleStyled = styled(Typography)(({ theme }) => ({
  fontFamily: theme.fonts.plutoRegular,
  color: theme.colors.steelGray,
}));

const StyledDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) => prop !== "isActionsVisible",
})(({ theme, isActionsVisible }) => ({
  padding: isActionsVisible
    ? `${theme.spaces.base}px ${theme.spaces.large}px`
    : theme.spaces.large,
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: `${theme.spaces.base}px ${theme.spaces.large}px ${theme.spaces.large}px`,
}));

const CancelButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.fonts.plutoRegular,
  color: theme.colors.primaryMain,
  borderColor: theme.colors.primaryMain,
  padding: `${theme.spaces.base}px ${theme.spaces.medium}px`,
  "&:hover": {
    borderColor: theme.colors.primaryMain,
    backgroundColor: theme.colors.primaryMain04,
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.fonts.plutoRegular,
  color: theme.colors.brandWhite,
  backgroundColor: theme.colors.primaryMain,
  padding: `${theme.spaces.base}px ${theme.spaces.medium}px`,
  "&:hover": {
    backgroundColor: theme.colors.primaryMain,
  },
}));

const BaseModal = ({
  open,
  children,
  title,
  subtitle,
  onCancelClick,
  onSubmitClick,
  onCloseClick,
  cancelButtonText = "Cancel",
  submitButtonText = "Submit",
  disabledConfirmButton = false,
  disabledCancelButton = false,
  CloseIconComponent = CloseIcon,
  titleStyles,
  onModalParamsClear,
  ...props
}) => {
  const isActionsVisible = useMemo(
    () => onCancelClick || onSubmitClick,
    [onCancelClick, onSubmitClick]
  );

  return (
    <DialogContainer
      open={open}
      TransitionProps={{
        onExited: () => {
          onModalParamsClear && onModalParamsClear();
        },
      }}
      {...props}
    >
      <DialogHeader>
        <HeaderTitles>
          <DialogTitleStyled titleStyles={titleStyles}>
            {title}
          </DialogTitleStyled>
          {subtitle && (
            <DialogSubtitleStyled variant="body2">
              {subtitle}
            </DialogSubtitleStyled>
          )}
        </HeaderTitles>

        {onCloseClick && (
          <IconButton onClick={onCloseClick} size="small">
            <CloseIconComponent fontSize="small" />
          </IconButton>
        )}
      </DialogHeader>

      {!!React.Children.count(children) && (
        <StyledDialogContent isActionsVisible={isActionsVisible}>
          {children}
        </StyledDialogContent>
      )}

      {isActionsVisible && (
        <StyledDialogActions>
          {onCancelClick && (
            <CancelButton
              onClick={(e) => {
                e.stopPropagation();
                onCancelClick();
              }}
              disabled={disabledCancelButton}
              variant="outlined"
              size="small"
            >
              {cancelButtonText}
            </CancelButton>
          )}
          <SubmitButton
            onClick={(e) => {
              e.stopPropagation();
              onSubmitClick();
            }}
            disabled={disabledConfirmButton}
            variant="contained"
            size="small"
          >
            {submitButtonText}
          </SubmitButton>
        </StyledDialogActions>
      )}
    </DialogContainer>
  );
};

export default BaseModal;
