import { TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import { useCallback, useState } from "react";

import { ReactComponent as SendIcon } from "../../assets/send.svg";
import {
  SUBMIT,
  SUBMIT_FEEDBACK,
  MAX_CHARS_COUNT,
} from "../../constants/index.js";

import BaseModal from "./BaseModal.jsx";

const SubHeader = styled(Typography)(({ theme }) => ({
  fontFamily: theme.fonts.plutoRegular,
  color: theme.colors.textBlack,
  fontSize: 18,
  paddingBottom: "16px",
}));

const TextArea = styled(TextField)(({ theme }) => ({
  fontFamily: theme.fonts.plutoRegular,
  border: `1px solid ${theme.colors.warmGrey90}`,
  borderRadius: theme.radius.small,
  padding: 13,
  "& svg": {
    alignSelf: "flex-end",
  },
}));

export const FeedbackModal = ({ open, onCancel, onSubmit }) => {
  const [feedback, setFeedback] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const onSubmitFeedback = useCallback(async () => {
    await onSubmit(feedback);
    setFeedback("");
  }, [feedback, onSubmit]);

  const onCancelClick = useCallback(() => {
    onCancel();
    setFeedback("");
  }, [onCancel]);

  const onSetFeedback = useCallback(
    (value) => {
      setFeedback(value.trim());
    },
    [setFeedback]
  );

  return (
    <BaseModal
      open={open}
      title={"Help us improve"}
      onCancelClick={onCancelClick}
      onSubmitClick={onSubmitFeedback}
      disabledConfirmButton={!feedback || feedback.length < 3}
      submitButtonText={isMobile ? SUBMIT : SUBMIT_FEEDBACK}
    >
      <SubHeader>
        We are always trying to improve our DPD chat service. Please give us
        details on how we can improve our results.
      </SubHeader>
      <TextArea
        InputProps={{ disableUnderline: true, endAdornment: <SendIcon /> }}
        inputProps={{ maxLength: MAX_CHARS_COUNT }}
        fullWidth
        variant="standard"
        multiline
        rows={6}
        placeholder="Let us know how we can improve..."
        onChange={(event) => onSetFeedback(event.target.value)}
      />
    </BaseModal>
  );
};

export default FeedbackModal;
