import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MessagesActions, UIActions, UserSelectors } from "../../store";
import { ROUTES } from "../../constants/navigation.js";
import { getPageLink } from "../../utils/navigation";
import { emailToSpannerId } from "../../utils/string.js";

export const useChatActions = () => {
  const sendMessagePromise = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { getUser } = UserSelectors;
  const currentUser = useSelector(getUser);
  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { topicId: activeTopicId } = useParams();

  const { email, id: userId } = currentUser;
  const { isSameChat, widgetId, message } = location?.state || {};

  const clearNavigationState = useCallback(
    // clear state and prevent reload page
    () => window.history.replaceState({}, ""),
    []
  );

  const resetChat = useCallback(() => {
    dispatch(MessagesActions.resetMessages());
    dispatch(UIActions.resetChatWidgets());
  }, [dispatch]);

  const onSendMessage = useCallback(
    async (message, depots) => {
      dispatch(MessagesActions.savePendingMessage(message));
      setIsTyping(true);
      //save promise to abort it later if needed
      if (depots) {
        sendMessagePromise.current = dispatch(
          MessagesActions.sendMessageVolumeSimulator({
            question: message,
            depots,
            topicId: activeTopicId || "",
            userId,
            spannerUserId: emailToSpannerId(email) || "",
          })
        );
      } else {
        sendMessagePromise.current = dispatch(
          MessagesActions.sendMessageDashboard({
            question: message,
            topicId: activeTopicId || "",
            userId,
            spannerUserId: emailToSpannerId(email) || "",
          })
        );
      }
      const { payload } = await sendMessagePromise.current;
      setIsTyping(false);

      if (!payload) {
        return null;
      }

      dispatch(MessagesActions.removePendingMessage(payload.messages[0]));
      dispatch(UIActions.setChatWidget(payload));

      if (!activeTopicId && payload.attributes?.topicId) {
        const path = getPageLink(ROUTES.CHAT_TOPIC, {
          topicId: payload.attributes.topicId,
        });

        navigate(path, { state: { isSameChat: true }, replace: true });
      }

      return payload;
    },
    [dispatch, navigate, activeTopicId, userId, email]
  );

  const fetchTopicMessages = useCallback(
    async (activeTopicId) => {
      setIsLoading(true);

      const { error } = await dispatch(
        MessagesActions.fetchMessagesByTopicId(activeTopicId)
      );

      setIsLoading(false);

      if (error) {
        navigate(ROUTES.CHAT, { replace: true });
      }
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    if (isSameChat) {
      clearNavigationState();
      return;
    }

    resetChat();

    sendMessagePromise.current?.abort();

    activeTopicId && fetchTopicMessages(activeTopicId);
  }, [
    activeTopicId,
    clearNavigationState,
    fetchTopicMessages,
    isSameChat,
    resetChat,
  ]);

  useEffect(() => {
    // show widget from the home page
    if (widgetId) {
      dispatch(UIActions.showWidgetInChat(widgetId));
      clearNavigationState();
    }

    // send a message from the home page
    if (message) {
      onSendMessage(message);
      clearNavigationState();
    }

    return () => {
      sendMessagePromise.current?.abort();
      resetChat();
    };
  }, [
    dispatch,
    message,
    widgetId,
    onSendMessage,
    clearNavigationState,
    resetChat,
  ]);

  return { resetChat, onSendMessage, isTyping, isLoading, activeTopicId };
};
